$base: #272b32;
$light: $base;
$highlight: #fc036f;

$light-background: rgba(255, 255, 255, 1);
$dark-background: rgba(0, 36, 61, 1);
$background-color: $light-background;

@media screen and (max-width: 480px) {
  .container {
    width: 90% !important;
  }

  .content {
    flex-direction: column;

    .posts-image-item {
      height: 50px !important;
      width: 50px !important;
      min-width: 50px !important;
    }
    .posts-top,
    .sidebar-short {
      width: 110%;
    }
  }
}

h1,
h2,
h3,
h4,
span,
p,
ul,
li {
  line-height: 1.6em;
  color: $light;
  a {
    color: $light;
  }
}

p,
ul {
  font-size: 0.7em;
  font-weight: 400;
}

h1 {
  font-size: 4em;
  line-height: 1;
  letter-spacing: -1px;
}

h2 {
  font-size: 0.9em;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 0px;
}

h3 {
  font-size: 0.75em;
  margin-top: 0px;
  margin-bottom: 0px;
}

h4 {
  font-size: 0.7em;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
}

i {
  color: $light;
}

i:hover,
h2:hover,
a:hover,
.hover:hover {
  color: $highlight !important;
}

a:link,
a:visited {
  text-decoration: none !important;
}

.no-decoration {
  text-decoration: none !important;
  pointer-events: none;
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

blockquote > p {
  font-style: italic;
}

.title {
  color: $light;
  font-family: "Arvo";
}

hr {
  border: none;
  height: 1px;
  /* Set the hr color */
  color: white; /* old IE */
  background-color: white; /* Modern Browsers */
  opacity: 0.2;
}

body {
  background: no-repeat center center cover;
  background-color: $background-color;
  font-family: "Open Sans", sans-serif;
}

.container {
  width: 60%;
  margin: auto;
  margin-bottom: 50px;
}

.posts-top,
.posts-all {
  width: 75%;
}

.post {
  height: 100%;
  width: auto;
  position: relative;
  padding-right: 5%;
  margin-bottom: 15px;
}

.post:hover {
  cursor: pointer;

  h2,
  p,
  a {
    cursor: pointer;
  }
}

.homepage-post {
  width: 95%;
  border-radius: 6px;
  cursor: pointer;
}
.homepage-post:hover {
  box-shadow: 0px 0px 0px 1px $highlight;
}

.date,
.categories,
.category {
}

.posts-list-item {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  transition: 0.2s;
  overflow: hidden;

  .posts-list-text {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .posts-image-item {
    height: 100px;
    width: 100px;
    min-width: 100px;
    border-radius: 6px;
    background-position: center;
    background-size: cover;
    background-color: white;
    margin-right: 20px;
    border: 0px;
    border-style: solid;
    border-color: $background-color;
  }
}

.sidebar-short {
  width: 25%;

  .short-posts {
    margin-bottom: 30px;
  }

  .short-post-link:hover > h3 {
    color: $highlight;
  }

  .short-post {
    margin-bottom: 10px;
  }

  .category:hover {
    color: $highlight;
  }
}

.current_page {
  h2 {
    text-decoration-line: underline !important;
    text-decoration-thickness: 0.8px !important;
    text-underline-offset: 4px !important;
    text-decoration-color: $highlight;
  }
}

.line {
  width: 100%;
  border-bottom: 1px solid $base;
  margin-bottom: 5px;
  margin-top: 3px;
  opacity: 0.2;
}

.menubar {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;
  nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    a {
      padding-right: 20px;
      font-family: Arvo;
    }

    .social {
      margin-left: auto;
      a {
        padding-right: 4px;
        text-decoration: none;
      }
    }
  }
}

.intro {
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  .intro-j {
    font-family: monospace;
    white-space: pre;
    font-size: 1em;
    color: $light;
    width: 15%;
    margin-right: 20px;
  }

  .intro-description {
    font-weight: 300;
    margin-top: 0px;
    text-align: center;
  }

  .intro-description {
    h2:hover {
      color: $base !important;
    }
  }

  .intro-name {
    font-family: Arvo;
    font-size: 2em;
    width: 85%;
    padding-top: 10px;
  }
}

.page-not-found {
  text-align: center;
  width: 100%;
  h1 {
    margin: 30px 0;
  }
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}

.post-content {
  img {
    width: 100%;
    max-width: 500px;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: transform 0.25s ease;
  }

  img:active {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  p {
    margin-bottom: 15px;
  }

  ul {
    width: 100%;
  }

  h2:hover {
    color: $light !important;
  }

  a {
    text-decoration-line: underline !important;
    text-decoration-thickness: 0.1px !important;
    text-underline-offset: 2px !important;
  }
}

.category-list-item {
  margin-bottom: 0px !important;
}

.div-post-title {
  width: 100%;
}

.div-subtitle {
  height: 35px;
}

.show-more {
  border-width: 0;
  padding: 0;
  background: white;
  margin-top: 0;
  font-size: 0.8em;
}

/* Style for code blocks */
pre {
  background-color: #f7f7f7; /* Background color for the code block */
  border: 1px solid #e1e1e1; /* Border around the code block */
  padding: 20px; /* Spacing around the code block */
  border-radius: 5px; /* Rounded corners for the code block */
  font-family: Monaco, monospace; /* Font for the code */
  font-size: 12px; /* Font size for the code */
  line-height: 1.5; /* Line height for the code */
  overflow-x: auto; /* Add horizontal scroll bar if code overflows */
}

/* Style for code syntax highlighting */
pre code {
  display: block; /* Ensure code is displayed as a block */
  padding: 0; /* Remove padding for code */
  margin: 0; /* Remove margin for code */
}

/* Style for code syntax highlighting colors */
pre code span {
  color: #333; /* Default text color for code */
}

/* Style for specific code syntax elements */
pre code .comment {
  color: #888; /* Color for comments */
}

pre code .keyword {
  color: #0074d9; /* Color for keywords */
}

pre code .string {
  color: #d35400; /* Color for strings */
}

/* Add more styles for different syntax elements as needed */
